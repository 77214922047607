import React, {forwardRef, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ActionShowAlert} from "../../../store/actions/alert";
import {Button, Divider, Grid, Group, Loader, Select, Text} from "@mantine/core";
import ResponseView from "./ResponseView";
import {CloudApi, RequestGet, RequestPost, serverUrls} from "../../../Layouts/RequestManager";
import IntegrationDrawer from "../IntegrationDrawer";

const FBLeads = ({props,value}) =>{
        const app ='fbLeads';
        const [event,setEvent] = useState("lead");
        const [connId,setConnId] = useState(value?.connId || undefined);
        const [openConn,setOpenConn] = useState(false);
        const [selected,setSelected] = useState(value?.selected || undefined);
        const [waitingWH,setWaitingWh] = useState(false);
        const [isLoading,setIsLoading] = useState(false);
        const [isError,setIsError] = useState(false);
        const [isEdited,setIsEdited] = useState(0);
        const [pages,setPages] = useState([]);
        const [forms,setForms] = useState([]);

        const [pageId,setPageId] = useState(value?.pageId || "");
        const [formId,setFormId] = useState(value?.formId || "");

        const [pageLoading,setPageLoading] = useState(false);
        const [formLoading,setFormLoading] = useState(false);

        const [sampleResponse,setSampleResponse] = useState(value?.webhookRes || undefined)
        const dispatch = useDispatch();
        const userId = useSelector(state => state.user.id);
        const {sessionId} = useSelector(state => state.active);
        const [error,setError] = useState("");

        useEffect(() =>{
            setIsEdited(isEdited+1);
        },[event,connId,selected]);

        useEffect(() =>{
            if(connId) getPages();
        },[connId]);
        useEffect(() =>{
          if(pageId) getForms();
        },[pageId,pages]);

        const getPages = async () =>{
            setPageLoading(true);
            const details = await RequestPost(`${CloudApi.fbLeads}/fbPages/${sessionId}`,{connId});
            setPageLoading(false);
            if(details?.status === 1){
                setPages(details?.pages);
            }else{
                setPages([]);
            }
        }
        const getForms = async () =>{
            setFormLoading(true);
            const page = pages?.find(a => a?.id === pageId);
            const details = await RequestPost(`${CloudApi.fbLeads}/fbForms/${sessionId}`,{connId,page});
            setFormLoading(false);
            if(details?.status === 1){
                setForms(details?.forms || []);
            }else{
                setForms([]);
            }
        }

        const updatePageId = (pageId) =>{
            setPageId(pageId);
            setFormId("")
        }
        const updateConnection = (conn) =>{
            setConnId(conn);
            setOpenConn(false);
            setWaitingWh(false);
            setFormId("");
            setPageId("");
        }
        const onEventChange = (event) =>{
            setEvent(event);
            setConnId(undefined);
            setSelected(undefined);
            setFormId("");
            setPageId("");
            setWaitingWh(false);
        }

        const handleSaveFlow = async () =>{

            const page = pages?.find(a => a?.id === pageId);
            const form = forms?.find(a => a?.id === formId);
            if(page && form){
                setIsLoading(true);
                const result = await RequestPost(`${CloudApi.fbLeads}/fbSubs/${sessionId}`,{page,form,pageId,formId,connId,event,step:props?.step,flowId: props?.id});
                console.log(result)
                setIsLoading(false);
                setIsError(false);
                if(result?.status === 1){
                    dispatch(ActionShowAlert({message: "Added Successfully",color: "green"}));
                    triggerWebhookListener();
                }else{
                    setError(result?.message);
                    setWaitingWh(false);
                }
            }else {
                setIsError(true);
            }


        }
        const triggerWebhookListener = () =>{
            setWaitingWh(true);
            // socket.emit("AddWebhookListen",{userId, id: props?.id, step: props?.step})
            // socket.on(`Flow${props?.id}_STEP${props?.step}`, handleWebhook);
        }
        const handleWebhook = (data) =>{
            dispatch(ActionShowAlert({message: "Webhook Captured", color: "green"}));
            setSampleResponse(data);
            setWaitingWh(false);
            setIsEdited(0);
        }

        const SelectItem = forwardRef(({ image, label, description, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <div>
                        <Text size="sm">{label}</Text>
                        <Text size="xs" color={"dimmed"}>
                            {description}
                        </Text>
                    </div>
                </Group>
            </div>
        ));
        const events =[{label: "New Lead",value: "lead",description: "When New Lead Created in specific Lead Form",}];

        return <>
            {openConn && <IntegrationDrawer app={'fbLeads'} connection={connId} updateConnection={updateConnection} onClose={() => setOpenConn(false)}/>}
            {/*{app && <Grid.Col span={6}>*/}
            {/*    <Select label={"Choose Event"}*/}
            {/*            itemComponent={SelectItem}*/}
            {/*            data={events}*/}
            {/*            value={event}*/}
            {/*            onChange={onEventChange} />*/}
            {/*</Grid.Col>}*/}
            {app && event && !connId && <Grid.Col span={6} mt={25}>
                <Button variant={"gradient"} onClick={() => setOpenConn(true)}>Connect With Facebook</Button>
            </Grid.Col>}
            {connId && <>
                <Grid.Col span={6} mt={25}>
                    <Button color={"green"} onClick={() => setOpenConn(true)}>Connected</Button>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider />
                </Grid.Col>
                <Grid.Col span={6}>
                    {pageLoading ? <Loader /> : <Select
                        label={"Select Pages"}
                        data={pages?.map(a => ({label: a?.name, value: a?.id}))}
                        value={pageId}
                        onChange={updatePageId}
                        error={isError && !pageId ? "Page is Required": ""}
                    />}
                </Grid.Col>
                {pageId && !pageLoading && <Grid.Col span={6}>
                    {formLoading ? <Loader /> :  <Select label={"Select Forms"}
                            data={forms?.map(a => ({label: a?.name, value: a?.id}))}
                            value={formId} onChange={setFormId}
                             error={isError && !formId ? "Page is Required": ""}
                    />}
                </Grid.Col>}
                <Grid.Col span={6}>
                    <Group position={'apart'} my={15}>
                        {!waitingWH ? <Button onClick={handleSaveFlow} loading={isLoading} variant={'gradient'}>Save & Send Request</Button> :
                            <Button loading={true}>Waiting For Webhook</Button>}
                    </Group>
                </Grid.Col>
            </>}
            {error && <Grid.Col span={12}>
                <Text color={"red"}>{error}</Text>
            </Grid.Col>}
            {sampleResponse && <ResponseView response={sampleResponse} onRefresh={triggerWebhookListener}/>}
        </>
}
export default FBLeads;
