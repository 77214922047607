import React, {useEffect, useState} from "react";
import {
    Drawer,
    Grid,
    Paper,
    Button,
    Group,
    ScrollArea,
    Divider,
    Stack,
    MultiSelect,
    SegmentedControl, Pagination
} from "@mantine/core";
import {FilledTextInput} from "../Sequence/CustomButton";
import {CheckBoxView, IconView, PageLoading, RadioButtonView} from "../Components/PageComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import * as XLSX from "xlsx";
import {formatMobileNumber} from "../../Constants/Functions";
import {ActionShowAlert} from "../../store/actions/alert";
import {IconPlus} from "@tabler/icons";
import {BoldText} from "../Components/TextComponents";
const AddUsers = ({id, onClose, onRefresh}) =>{

    const {sessionId} = useSelector(state => state.active);
    const [createType, setCreateType] = useState("Manual");
    const [bulkData,setBulkData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [update,setUpdate] = useState(false);
    const [render,setRender] = useState(1);
    const [page,setPage] = useState(1);
    const dispatch = useDispatch();




    const handleUpload = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            let [keys,...dataparsed] = XLSX.utils.sheet_to_json(ws, {header:1});
            const objectsArray = dataparsed.map((arr) => {
                let obj = {};
                keys.forEach((key, index) => {
                    obj[key] = arr[index] || null; // Handles missing data in the array
                });
                const {mobile,name} = obj;
                return {mobile: formatMobileNumber(mobile), name};
            });
            setBulkData(objectsArray?.filter(a => !!a?.mobile));
        };
        reader.readAsBinaryString(f)
    };
    const handleBulkUpload = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.tracker}/addUsers/${sessionId}`,{id, users: bulkData,update });
        setIsLoading(false);
        dispatch(ActionShowAlert({message: data?.message}));
        onClose();
        onRefresh();
    }
    const onChange = (i,key,value) =>{
        const d = bulkData;
        d[i] = {...d?.[i], [key]: value};
        setBulkData(d);
        setRender(render+1);
    }
    const onDelete = (index) =>{
        setBulkData(bulkData?.filter((a,i) => i !== index));
        setRender(render+1);
    }


    return <Drawer position={"right"} opened={true} onClose={onClose} size={"40%"} px={10}>
        <PageLoading isLoading={isLoading}>
            <Grid mx={10}>
                 <Grid.Col span={12}>
                    <SegmentedControl data={["Manual", "Excel"]?.map(a => ({label: a, value: a}))} value={createType} onChange={setCreateType}/>
                </Grid.Col>
                {createType === "Excel" && <Grid.Col span={12}>
                    <input type={"file"} onChange={handleUpload} />
                </Grid.Col>}
                <Grid.Col span={12}>
                    <Group position={"apart"} my={10}>
                        <CheckBoxView size={20} text={"Update Days of user if Already Exists"} isSelected={update} onClick={setUpdate} />
                        <Button compact leftIcon={<IconPlus />} onClick={() => setBulkData(data => ([...data, {name: "", mobile: ""}]))}>Add Row</Button>
                    </Group>
                    <Group position={"apart"} my={10}>
                        <BoldText text={`Total: ${bulkData?.length}`} size={"md"} />
                        {bulkData?.length > 10 && <Pagination total={Math.ceil(bulkData?.length / 10)} page={page} onChange={setPage}/>}
                    </Group>
                </Grid.Col>
                {bulkData?.slice((page-1) * 10 , page * 10)?.map((a,i) =>{
                    return <Grid.Col span={12}>
                        <Group>
                            <FilledTextInput label={"name"} value={a?.name} onChange={(value) => onChange(i,"name",value)} />
                            <FilledTextInput label={"Mobile"} value={a?.mobile} onChange={(value) => onChange(i,"mobile",value)} />
                            <IconView iconType={"delete"} onClick={() => onDelete(i)} />
                        </Group>
                    </Grid.Col>
                })}
                <Grid.Col span={12}>
                    <Group position={"center"}>
                        <Button loading={isLoading} onClick={handleBulkUpload}>Save Contacts</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </PageLoading>

    </Drawer>
}

export default AddUsers;