import {
    Tabs,
    Badge,
    Pagination,
    Group,
    Select,
    MultiSelect,
    TextInput,
    Paper,
    Button,
    Drawer,
    Text, Stack, Divider, Modal
} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {CheckBoxView, Header, IconView, PageLoading, TableView} from "../Components/PageComponents";
import {BoldText, ErrorText, SmallText} from "../Components/TextComponents";
import {DatePicker} from "@mantine/dates";
import {convertUnixToFormat, formatTime} from "../../Constants/Functions";
import MessageView from "../Inbox/MessageView";
import _ from "underscore";
import {IconCalendar, IconCheck, IconChecks, IconDownload, IconMessage, IconSend, IconX} from "@tabler/icons";
import {LogStatus} from "../../Constants/Strings";
import {Colors} from "../../Constants/Colors";
import AlertPopup from "../Alerts/AlertPopup";
import {ActionHidePopup, ActionShowPopup} from "../../store/actions/alert";

const statusArray =[{label:"Read",value: "read"},{label:"Delivered",value: "delivered"},{label:"Sent",value: "sent"},{label:"Failed",value: "failed"}];
const statusColor = {
    failed: "red",
    read: "green",
    delivered: "orange",
    sent: "yellow",
}
const types = [
    {label: "All", value: "all"},
    {label: "Schedule", value: "Schedule"},
    {label: "Program", value: "Program"},
]
const typeSingle = [
    {label: "All", value: "all"},
    {label: "Inbox", value: "Inbox"},
    {label: "Chatbot", value: "Chatbot"},
    {label: "Sequence", value: "Sequence"},
    {label: "Forms", value: "Forms"},
]
const StatusArray = [
    {label: "All", value: "all"},
    ...LogStatus?.map((a,i) => ({label: a?.label, value: i }))
]

const MessageLogs = () =>{
    const {sessionId,r365} = useSelector(state => state.active);

    const [open,setOpen] = useState(false);
    const [date,setDate] = useState(new Date());
    const downloadExcel = () =>{
        setOpen(false)
        window.open(`${CloudApi.downloadAllMsg}/${sessionId}?date=${date}`)
    }


    return <>
        <Header title={"MessageLogs"} subTitle={"Get All Type of Message Logs Here"} buttons={[{type: "download",title: "Download All Message Stats"}]} buttonProps={[{onClick :() => setOpen(true)}]} />
        {open && <Modal title={<BoldText text={"Download Messages"} />} opened={true} onClose={() => setOpen(false)}>
            <DatePicker label={"Choose Date"} value={date} onChange={setDate} />
            <Group position={"center"} my={20}>
                <Button onClick={downloadExcel}>Download</Button>
            </Group>
        </Modal>}

        <Tabs  keepMounted={false} defaultValue="group">
            <Tabs.List grow>
                <Tabs.Tab value="group">Group Logs</Tabs.Tab>
                <Tabs.Tab value="single">Single Logs</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="group" pt="xs">
                <GroupLogs />
            </Tabs.Panel>
            <Tabs.Panel value="single" pt="xs">
                <SingleLogs type={"Optin"} />
            </Tabs.Panel>

        </Tabs>
    </>
}
export default MessageLogs;
const GroupLogs = ({}) =>{
    const {sessionId,r365} = useSelector(state => state.active);
    const groupTypes = r365?.productId?.length > 0 ? [...types, {label: "R365",value: "R365"}]: types;
    const [isLoading,setIsLoading] = useState(false);
    const [logs,setLogs] = useState([]);
    const [page,setPage] = useState(1);
    const [totalCount,setTotalCount] = useState(0);
    const [date,setDate] = useState(new Date());
    const [search,setSearch] = useState("");
    const [openLog,setOpenLog] = useState();
    const [type,setType] = useState("all");
    const [status,setStatus] = useState("all");
    const [onGoing,setOnGoing] = useState(false);

    const handleFilter = () =>{
        setPage(1);
        fetchLogs();
    }
    useEffect(() =>{
        fetchLogs();
    },[page]);
    const fetchLogs = async () =>{
        setIsLoading(true);
        const list  = await RequestPost(`${CloudApi.logs}`,{sessionId,type,page,date,search,onGoing,status});
        setLogs(list?.logs);
        setTotalCount(list?.totalCount);
        setIsLoading(false);
    }
    const downloadLog = (logId,title) =>{
        window.open(`${CloudApi.logDownload}/${sessionId}/${title}/${logId}`);
    }
    const dispatch = useDispatch();
    const ResendAllMessages = (logId) =>{
        dispatch(ActionShowPopup({
            title: "Resend Messages",
            content: `This will Resend All Failed Message Again. Are you sure ?`,
            successTitle: "Resend",
            cancelTitle: "Cancel",
            onSuccess: async () => {
                const list  = await RequestGet(`${CloudApi.ResendBulkMessage}/${sessionId}/${logId}`);
                dispatch(ActionHidePopup());
                fetchLogs();
            }
        }));
    }

    return <>
        {/*<Paper shadow={"md"} withBorder p={5}>*/}
            <Group my={5}>
                <TextInput size={"xs"} variant={"filled"} placeholder={"Search title"} value={search} onChange={(e) => setSearch(e.target.value)} />
                <DatePicker size={"xs"} variant={"filled"} placeholder={"Choose Date"} value={date} onChange={setDate} />
                <Select size={"xs"} data={groupTypes} value={type} onChange={setType} />
                <Select size={"xs"} data={StatusArray} value={status} onChange={setStatus} />
                <CheckBoxView text={"Ongoing"} isSelected={onGoing} onClick={() => setOnGoing(!onGoing)} />
                <Button compact onClick={handleFilter}>Filter</Button>
            </Group>
        {/*</Paper>*/}
        <PageLoading isLoading={isLoading}>
        <Group my={10} position={"apart"}>
            <SmallText text={`Total Results: ${totalCount}`} weight={"bold"} />
            <Pagination size={"xs"} total={Math.ceil(totalCount/10)} page={page} onChange={setPage} />
        </Group>

            <TableView headers={["Title","Type","Total Msg", "Success","Delivered", "Read", "Failed", "Time","Status","Options"]}>
                {logs?.map(a => {
                    return <tr>
                        <td>{a?.title}</td>
                        <td>{a?.type}</td>
                        <td>{a?.msgLength}</td>
                        <td>{a?.stats?.success}</td>
                        <td>{a?.stats?.delivered}</td>
                        <td>{a?.stats?.read}</td>
                        <td>{a?.stats?.failed}</td>
                        <td>{moment.unix(a?.created).format("lll")}</td>
                        <td> <Badge color={LogStatus[a?.status]?.color}>{LogStatus[a?.status]?.label}</Badge></td>
                        <td>
                            <Group>
                                <IconView label={"View Logs"} iconType={"view"} onClick={() => setOpenLog(a?.logId)} />
                                <IconView label={"Download Leads"} icon={<IconDownload />} onClick={() => downloadLog(a?.logId,a?.title)} />
                                {a?.stats?.failed > 0 && <IconView label={"Resend Failed"} iconType={"refresh"} onClick={() => ResendAllMessages(a?.logId)} />}
                            </Group>
                        </td>
                    </tr>
                })}
                {logs?.length === 0 && <tr><td colSpan={8} align={"center"}>No Data available</td></tr>}
            </TableView>
        </PageLoading>

        {openLog && <GroupLogDetails sessionId={sessionId} logId={openLog} onClose={() => setOpenLog()} />}
    </>
}
const GroupLogDetails = ({logId,sessionId,onClose}) =>{
    const[isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    const [stats,setStats] = useState([]);
    const [statsCount,setStatsCount] = useState([]);
    useEffect(()=>{
        fetchLogDetails();
    },[logId]);
    const fetchLogDetails = async () =>{
        setIsLoading(true);
        const data = await RequestGet(`${CloudApi.logDetails}/${sessionId}/${logId}`);
        setDetails(data?.details);
        setStats(data?.stats || []);
        setStatsCount(_.countBy(data?.stats, "status"));
        setIsLoading(false);
    }
    console.log(_.countBy(stats, "status"));

    return <Drawer size={"50%"} opened={true} position={"right"} onClose={onClose} title={<b>Message Details</b>}>
        <PageLoading isLoading={isLoading}>
            <Stack sx={{gap: 3}} mx={10}>
                <Group position={'apart'} my={3}>
                    <Text size={20} weight={'bold'}>{details?.title}</Text>
                    <Group>
                        <Group sx={{gap: 3}}><IconMessage size={18} color={Colors.Primary} /><Text>{details?.msgLength || 0}</Text></Group>
                        <Group sx={{gap: 3}}><IconCheck  size={18} color={'grey'} /><Text>{statsCount?.sent || 0 + statsCount?.delivered || 0 + statsCount?.read || 0}</Text></Group>
                        <Group sx={{gap: 3}}><IconChecks  size={18} color={'grey'} /><Text>{statsCount?.delivered || 0 + statsCount?.read || 0}</Text></Group>
                        <Group sx={{gap: 3}}><IconChecks  size={18} color={'green'} /><Text>{statsCount?.read || 0}</Text></Group>
                        <Group sx={{gap: 3}}><IconX size={18} color={'red'} /><Text>{statsCount?.failed || 0}</Text></Group>
                    </Group>
                </Group>
                <Group position={'apart'} my={3}>
                    <Group sx={{gap: 3}}>
                        <IconCalendar size={18} color={'#ccc'} />
                        <Text size={14} color={'dimmed'}>{convertUnixToFormat(details?.started)} {details?.status === 2 ?  `(${formatTime(details?.completed - details?.started)})`: ``}</Text>
                    </Group>
                    <Badge color={LogStatus[details?.status]?.color}>{LogStatus[details?.status]?.label}</Badge>
                </Group>
                <Divider />
            </Stack>
            <UserStatsView stats={stats} onRefresh={fetchLogDetails} />
        </PageLoading>
    </Drawer>
}
const UserStatsView = ({stats,onRefresh}) =>{
    const statCount = _.countBy(stats,"status")
    const [filtered,setFiltered] = useState(stats);
    const [search,setSearch] = useState("");
    const [status,setStatus] = useState("all");
    const [page,setPage] = useState(1);
    const [render,setRender] = useState(1);
    const dispatch = useDispatch();
    const {sessionId} = useSelector(state => state.active);
    const [alert,setAlert] = useState();

    useEffect(() =>{
        let filter = stats;
        if(search?.trim() !== "") filter = filter?.filter(a => a?.mobile?.includes(search?.trim()));
        if(status !== "all") filter = filter?.filter(a => a?.status === status);
        setFiltered(filter);
        setRender(render+1);
        setPage(1);
    },[stats,search,status])
    const ResendMessages = (id) =>{
        setAlert( {
            title: "Resend Messages",
            content: `This will Resend the Particular Message Again. Are you sure ?`,
            successTitle: "Resend",
            cancelTitle: "Cancel",
            onSuccess: async () => {
                const list  = await RequestGet(`${CloudApi.ResendMessage}/${sessionId}/${id}`);
                setAlert();
                onRefresh();
            }
        });
    }

    return <>
        {alert && <AlertPopup opened={true} onClose={() => setAlert()} {...alert} />}
        <Paper shadow={"md"} withBorder p={5}>
            <Group my={5} position={"apart"}>
                <Group>
                    <TextInput size={"xs"} variant={"filled"} placeholder={"Search Number"} value={search} onChange={(e) => setSearch(e.target.value)} />
                    <Select size={"xs"} variant={"filled"} data={[{label: "All",value: "all"},...statusArray]} placeholder={"Choose Status"} value={status} onChange={setStatus} />
                </Group>
                <Pagination size={"xs"} total={Math.ceil(stats?.length / 10)} page={page} onChange={setPage} />
            </Group>
        </Paper>
        <TableView headers={["Mobile","Status","Comments","Retries","Options"]}>
            {filtered?.slice((page-1)*10,page * 10)?.map(a => <tr>
                <td>{a?.mobile}</td>
                <td><Badge color={statusColor[a?.status]}>{a?.status}</Badge></td>
                <td>{a?.reason}</td>
                <td>{a?.retryCount}</td>
                <td>
                    <Group>
                        {a?.status === "failed" && <IconView
                            label={"Resend"}
                            icon={<IconSend/>}
                            onClick={() => ResendMessages(a?._id)}
                        />}
                    </Group>
                </td>
            </tr>)}
        </TableView>
    </>
}



const SingleLogs = ({}) =>{
    const {sessionId,r365} = useSelector(state => state.active);
    const singleTypes = r365?.productId?.length > 0 ? [...typeSingle, {label: "R365",value: "R365"}]: typeSingle;

    const [isLoading,setIsLoading] = useState(false);
    const [logs,setLogs] = useState([]);
    const [totalCount,setTotalCount] = useState(0);
    const [page,setPage] = useState();
    const [date,setDate] = useState(new Date());
    const [status,setStatus] = useState([]);
    const [search,setSearch] = useState("");;
    const [openLog,setOpenLog] = useState();
    const [type,setType] = useState("all");
    const dispatch = useDispatch();

    useEffect(() =>{
        fetchLogs();
    },[page]);
    const handleFilter = () =>{
        setPage(1);
        fetchLogs();
    }
    const fetchLogs = async () =>{
        setIsLoading(true);
        const list  = await RequestPost(`${CloudApi.singleLogs}`,{sessionId,type, page,date,status,search});
        setLogs(list?.logs);
        setTotalCount(list?.totalCount);
        setIsLoading(false);
    }
    const ResendMessages = (id) =>{
        dispatch(ActionShowPopup( {
            title: "Resend Messages",
            content: `This will Resend the Particular Message Again. Are you sure ?`,
            successTitle: "Resend",
            cancelTitle: "Cancel",
            onSuccess: async () => {
                const list  = await RequestGet(`${CloudApi.ResendMessage}/${sessionId}/${id}`);
                dispatch(ActionHidePopup());
                fetchLogs();
            }
        }));
    }
    return <>
        {/*<Paper shadow={"md"} withBorder p={5}>*/}
            <Group my={5}>
                <TextInput size={"xs"} variant={"filled"} placeholder={"Search Number"} value={search} onChange={(e) => setSearch(e.target.value)} />
                <MultiSelect size={"xs"} variant={"filled"} data={statusArray} placeholder={"Choose Status"} value={status} onChange={setStatus} />
                <DatePicker size={"xs"} variant={"filled"} placeholder={"Choose Date"} value={date} onChange={setDate} />
                <Select size={"xs"} data={singleTypes} value={type} onChange={setType} />
                <Button compact onClick={handleFilter}>Filter</Button>
            </Group>
        {/*</Paper>*/}
        <PageLoading isLoading={isLoading}>
        <Group my={10} position={"apart"}>
            <SmallText text={`Total Results: ${totalCount}`} weight={"bold"} />
            <Pagination size={"xs"} total={Math.ceil(totalCount/10)} page={page} onChange={setPage} />
        </Group>
            <TableView headers={["Mobile","Type","Status","Reason","Time","Retries","Options"]}>
                {logs?.map(a => {
                    return <tr>
                        <td width={"10%"}>{a?.mobile}</td>
                        <td width={"10%"}>{a?.type}</td>
                        <td width={"10%"}><Badge color={statusColor[a?.status]}>{a?.status}</Badge></td>
                        <td width={"40%"}>{a?.reason}</td>
                        <td width={"15%"}>{moment.unix(a?.created).format("lll")}</td>
                        <td width={"5%"}>{a?.retryCount}</td>
                        <td width={"10%"}>
                            <Group>
                                {a?.status === "failed" && <IconView label={"Resend"} icon={<IconSend/>} onClick={() => ResendMessages({sessionId,id: a?._id,dispatch})}/>}
                                <IconView label={"View Logs"} iconType={"view"} onClick={() => setOpenLog(a?._id)} />
                            </Group>
                        </td>
                    </tr>
                })}
                {logs?.length === 0 && <tr><td colSpan={5} align={"center"}>No Data available</td></tr>}
            </TableView>
        </PageLoading>
        {openLog && <SingleLogDetails sessionId={sessionId} id={openLog} onClose={() => setOpenLog()} />}
    </>
}
const SingleLogDetails = ({id,sessionId,onClose}) =>{
    const[isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();


    useEffect(()=>{
        fetchLogDetails();
    },[id]);
    const fetchLogDetails = async () =>{
        setIsLoading(true);
        const data = await RequestGet(`${CloudApi.singleLogDetails}/${sessionId}/${id}`);
        setDetails(data?.details);
        setIsLoading(false);
    }

    return <Drawer size={"50%"} opened={true} position={"right"} onClose={onClose} title={<b>Message Details</b>}>
            <PageLoading isLoading={isLoading}>

                <Paper shadow={"md"} withBorder p={5}  mx={5}>
                    <Group position={"apart"}>
                        <BoldText text={details?.mobile} />
                        <Badge color={statusColor[details?.status]}>{details?.status}</Badge>
                    </Group>
                    <Group position={"apart"}>
                        <SmallText text={`From ${details?.type}`} />
                        <SmallText text={details?.reason} align={"left"} color={"red"} />
                    </Group>
                </Paper>
                <Group m={5}>
                    {details?.receipts?.map(a =>{
                        return <>
                            <Badge color={statusColor[a?.status]}>{a?.status}</Badge>
                            <SmallText text={convertUnixToFormat(a?.timestamp)} />
                        </>
                    })}
                </Group>
                <Paper shadow={"md"} withBorder p={5} mx={5}>
                    <BoldText  text={"Message Preview"} />
                    <MessageView message={details?.message} />
                </Paper>
            </PageLoading>
    </Drawer>
}

