import {
    Group,
    Loader,
    Paper,
    Stack,
    Text,
    Button,
    ActionIcon,
    Tooltip,
    Table,
    Modal,
    Badge,
    CopyButton
} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {Colors} from "../../Constants/Colors";
import React, {useEffect} from "react";
import {
    IconBrandGoogleAnalytics, IconCheck, IconCircle, IconCircleCheck, IconCopy, IconDiscountCheck,
    IconDownload,
    IconEdit, IconEye, IconFileUpload,
    IconList, IconLogout, IconMessageChatbot,
    IconPlus,
    IconRefresh, IconSquare, IconSquareCheck, IconToggleLeft, IconToggleRight,
    IconTrash, IconX
} from "@tabler/icons";
import {CloudApi} from "../../Layouts/RequestManager";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {BoldText, SmallText} from "./TextComponents";
const IconsList = {
    refresh: <IconRefresh />,
    add:<IconPlus />,
    download:<IconDownload />,
    delete:<IconTrash />,
    edit:<IconEdit />,
    list:<IconList />,
    lead:<IconBrandGoogleAnalytics />,
    view:<IconEye />,
    logout:<IconLogout />,
}
const getIcons = (type, props)=>{
    switch (type){
        case 'add':
            return <IconPlus size={25} color={Colors.Primary} {...props}/>
        case 'edit':
            return <IconEdit size={18} color={Colors.Primary} {...props}/>
        case 'delete':
            return <IconTrash size={18} color={'red'}  {...props} />
        case 'view':
            return <IconEye size={18} color={Colors.Primary}  {...props} />
        case 'refresh':
            return <IconRefresh size={18} color={Colors.Primary}  {...props} />
        case 'upload':
            return <IconFileUpload size={18} color={Colors.Primary}  {...props} />
        case 'lead':
            return <IconBrandGoogleAnalytics size={18} color={Colors.Primary}  {...props} />
        case 'logout':
            return <IconLogout size={18} color={Colors.Primary}  {...props} />
    }
}
const BSMToolTip = ({label ,children}) =>{
    return label ?<Tooltip  withArrow={false} offset={2} sx={{ backgroundColor: '#ccc', backdropFilter: 'blur(20px)', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.9)'}}
                            label={<Text weight={'bold'} color={'black'}>{label}</Text>}
                            children={children} /> : children;
}
export const CustomTooltip = ({props}) => <BSMToolTip {...props}/>
export const PageLoading = ({isLoading,children}) =>{
    return isLoading ? <Loader sx={(theme) => ({ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"})} />: <>{children}</>
}
export const Header = ({title,subTitle, buttons, buttonProps, link}) =>{
    const isMobile = useMediaQuery('(max-width: 700px)');
    const navigation = useNavigate();
    const {alias} = useSelector(state => state.active);
    return <Paper shadow={"lg"} sx={{borderBottomStyle: "solid"}} pl={5} mb={10}>
        <Group position={"apart"}>
            <Stack sx={{gap: 1}} mb={!isMobile  ? 10: 5}>
                <Group position={'left'} align={'center'} sx={{gap: 3}}>
                    {link && <Text size={isMobile ? 14 : 16} weight={'bold'} color={Colors.Primary}
                           onClick={() => navigation(`/device/${alias}/${link?.link}`)} sx={{cursor: "pointer"}}>{link?.name} / </Text>}
                    <Text size={isMobile ? 14 : 16} weight={'bold'} color={Colors.Primary}>{title}</Text>
                </Group>
                {subTitle && !isMobile && <Text size={12} color={'dimmed'} weight={600}>{subTitle}</Text>}
            </Stack>
            <Group>
                {buttons?.length > 0 && isMobile && buttons?.map((a,i) => <IconView {...buttonProps?.[i]} size={"xs"} icon= {IconsList[a?.type]} color={Colors.Primary}/>)}
                {buttons?.length > 0 &&!isMobile &&  buttons?.map((a,i) => <Button {...buttonProps?.[i]} size={"xs"} leftIcon= {IconsList[a?.type]} color={Colors.Primary}>{a?.title}</Button>)}
            </Group>
        </Group>
    </Paper>
}

export const IconView = ({iconType,icon,onClick,label,color,variant,outerColor}) =>{
    const props = color ? {color}: {};
    return <BSMToolTip label={label}>
        <ActionIcon variant={variant || 'subtle'} size={'sm'} onClick={onClick} color={outerColor || color}>
            {iconType ? getIcons(iconType, props): icon}
        </ActionIcon>
    </BSMToolTip>
}
export const IconWithText = ({icon,label,text,onClick,color, textProps}) =>{
    return <BSMToolTip label={label}>
        <Group mb={3} mx={5} sx={{gap: 3,cursor: onClick ? "pointer": "default"}} onClick={()=>onClick()}>
            {icon}
            <Text size={16} color={color || "black"} {...textProps}>{text}</Text>
        </Group>
    </BSMToolTip>
}
export const TableView = ({headers,children}) =>{
    return <Table striped>
        <thead>
            <tr style={{backgroundColor: "#ccc"}}>{headers?.map(a => <th>{a}</th>)}</tr>
        </thead>
        <tbody>{children}</tbody>
    </Table>
}

export const AlertPopup = ({show,title,content,successTitle,cancelTitle,onSuccess,onCancel}) =>{
    return <Modal opened={show} title={title} onClose={onCancel}>
        <Stack sx={{gap: 3}}>
            {content?.split('\n')?.map(a => <Text m={10}>{a}</Text>)}
        </Stack>
        <Group position={'apart'} direction={'row'}>
            <Button color={'gray'} onClick={onCancel}>{cancelTitle || 'Cancel'}</Button>
            <Button onClick={onSuccess}>{successTitle || 'Okay'}</Button>
        </Group>
    </Modal>
}
export const LoadingText = ({label}) =>{
    return <Group mt={25}>
        <Loader size={"sm"} variant={"gradient"}  />
        <Text weight={"bold"} variant={"gradient"}>{label}</Text>
    </Group>
}
export const getValidBadge = ({valid,error}) =>{
    return valid ? <IconDiscountCheck color={'green'} /> : error ? <Badge color={'red'} >Required</Badge>: <div />;
}

export const CheckBoxView = ({isSelected,text,onClick, size}) =>{
    return <Group onClick={() => onClick(!isSelected)} sx={{cursor: "pointer",gap: 3}}>
        {isSelected ? <IconSquareCheck size={size || 16} /> : <IconSquare size={size || 16} />}
        <Text>{text}</Text>
    </Group>
}
export const RadioButtonView = ({isSelected,text,onClick}) =>{
    return <Group onClick={() => onClick(!isSelected)} sx={{cursor: "pointer",gap: 3}}>
        {isSelected ? <IconCircleCheck size={16} /> : <IconCircle size={16} />}
        <Text>{text}</Text>
    </Group>
}
export const SwitchView  = ({isSelected, text,onClick,onEdit}) =>{
    return <Group position={'apart'} onClick={onEdit} sx={{cursor: "pointer",color: Colors.Secondary}}>
        {text && <Text weight={'bold'} mx={5}>{text}</Text>}
        <ActionIcon variant={'subtle'} sx={{backgroundColor: Colors.Secondary}} onClick={(e) => {
            e.stopPropagation();
            onClick(!isSelected);
        }}>
            {isSelected ? <IconToggleRight color={'green'} /> : <IconToggleLeft color={'red'} />}
        </ActionIcon>
    </Group>
}

export const FlowWebhookButton = ({id}) =>{
    return  <CopyButton value={`${CloudApi.bsmFlowWebhook}/${id}`} >
        {({ copied, copy }) => (
            <Button size={"xs"} leftIcon={copied ? <IconCheck color={"green"} />: <IconCopy color={"blue"} />} color={copied? "green": "blue"} variant={"outline"}  onClick={copy} mt={25}>
                {copied ? 'Copied Webhook' : 'Copy Webhook'}
            </Button>
        )}
    </CopyButton>
}

export const FlowSuccessMsg = ({message,onClose}) =>{
    useEffect(() =>{
       setTimeout(() => onClose() , 3000)
    },[])
    return <Group><IconCircleCheck color={"green"} /><Text weight={"bold"} color={"green"}>{message}</Text></Group>
}
export const FlowErrorMsg = ({message,onClose}) =>{
    useEffect(() =>{
       setTimeout(() => onClose() , 3000)
    },[])
    return <Group><IconX color={"red"} /><Text weight={"bold"} color={"red"}>{message}</Text></Group>
}
export const StatusMessage = ({message,status,onClose}) =>{
    useEffect(() =>{
       setTimeout(() => onClose() , 3000)
    },[])
    return  <>
        {status === "success" && <FlowSuccessMsg message={message} onClose={onClose}/>}
        {status === "error" && <FlowErrorMsg message={message} onClose={onClose} />}
    </>
}



export const ProfileCardItem = ({icon, text}) =>{
    return <Group sx={{gap: 20, borderBottom: "0.5px solid #ccc"}} p={5}>
        {icon}
        <Text size={"xs"} color={"dimmed"}>{text}</Text>
    </Group>
}

export const DetailsCard = ({label,value,rightComponent,...props}) =>{
    return <Paper shadow={"md"} withBorder p={5}  sx={{width: "100%"}} {...props}>
        <Stack sx={{gap: 3}}>
            <SmallText text={label} size={"xs"} color={"dimmed"} />
            {rightComponent ? <>{rightComponent}</>: <SmallText text={value}/>}
        </Stack>
    </Paper>
}
export const PrefCountView = ({type}) => {
    const {prefrences,prefUsed} = useSelector(state => state.active);
    console.log("Pref",prefUsed);
   return <Group>
        <Paper shadow={"md"} withBorder px={10}>
            <Group>
                <IconView icon={<IconMessageChatbot/>}/>
                <BoldText text={`${prefUsed?.[type]} / ${prefrences?.[type]} ${type}`}/>
            </Group>
        </Paper>
    </Group>
}