import {CheckBoxView, Header, IconView, PageLoading, TableView} from "../Components/PageComponents";
import {useEffect, useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {Group, Pagination, Button, Paper, Grid, MultiSelect} from "@mantine/core";
import CreateContact from "./CreateContact";
import moment from "moment";
import _ from "underscore";
import React from "react";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import CRMUser from "./CRMUser";
import {BoldText} from "../Components/TextComponents";
import CRMFilter from "./CRMFilter";
import {IconFilter, IconManualGearbox, IconSettings, IconUsers} from "@tabler/icons";
import Settings from "./Settings";

const PageHeading = {
    title: "Contact Manager",
    subTitle: "Here You handle Numbers With Tags",
    buttons: [
        {type: "refresh",title: "Refresh"},
        {type: "add",title: "Add Contacts"},
    ]
}

const CRMList = () =>{
    const {sessionId,tags} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [contacts,setContacts] = useState([]);
    const [filter,setFilter] = useState({});
    const [total,setTotal] = useState(1);
    const [page,setPage] = useState(1);
    const [isCreate,setIsCreate] = useState(false);
    const [isEdit,setIsEdit] = useState();
    const [isView,setIsView] = useState();
    const [isOpenFilter,setOpenFilter] = useState(false);
    const [isOpenSettings,setOpenSettings] = useState(false);
    const [isBulk,setIsBulk] = useState(false);
    const [selected,setSelected] = useState([]);
    const [tagsList,setTagsList] = useState(tags?.map(a => ({label:a,value: a})) || []);
    const [selectedTags,setSelectedTags] = useState([]);
    const [render,setRender] = useState(1);

    const dispatch = useDispatch();

    useEffect(() =>{
        getDetails({params: filter});
    },[page]);

    const getDetails = async ({params = {}}) =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.CRM}/list/${sessionId}`,{page, ...params});
        setContacts(data?.contacts || []);
        setTotal(data?.total || 0);
        setIsLoading(false);
    }
    const deleteContact = async (mobile) =>{
        dispatch(ActionShowPopup({
            title: "Delete Contact",
            content: "Are you sure want to Delete?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.CRM}/deleteContact/${sessionId}`,{mobile});
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert({message: data?.message}));
                getDetails({})
            }
        }))
    }
    const deleteSelectedContact = async () =>{
        if(selected?.length > 0){
            dispatch(ActionShowPopup({
                title: "Delete Selected Contact",
                content: "It will Delete The Selected Contacts. You Can't Recover those.",
                successTitle: "Continue",
                cancelTitle: "Cancel",
                hideLoading: true,
                onSuccess: async () => {
                    dispatch(ActionHidePopup());
                    dispatch(ActionShowPopup({
                        title: "Delete Selected Contact",
                        content: "Are you Sure want to Delete ?.",
                        successTitle: "Delete",
                        cancelTitle: "Cancel",
                        onSuccess: async () =>{
                            const data = await RequestPost(`${CloudApi.CRM}/bulkDelete/${sessionId}`,{selected});
                            setSelected([]);
                            setPage(1);
                            setIsBulk(false);
                            dispatch(ActionHidePopup());
                            dispatch(ActionShowAlert({message: data?.message}));
                            getDetails({})
                        }
                    }));
                }
            }))
        }else{
            dispatch(ActionShowAlert({message: "No Contacts Selected", color:"red"}))
        }
    }
    const BulkAssignTags = async () =>{
        if(selected?.length > 0 && selectedTags?.length > 0){
            dispatch(ActionShowPopup({
                title: "Assign Tags",
                content: "Are you sure want to Assign Tags?",
                successTitle: "Assign Tags",
                cancelTitle: "Cancel",
                onSuccess: async () => {
                    const data = await RequestPost(`${CloudApi.CRM}/bulkAssignTags/${sessionId}`,{selected,tags: selectedTags});
                    setSelected([]);
                    setPage(1);
                    setIsBulk(false);
                    dispatch(ActionHidePopup());
                    dispatch(ActionShowAlert({message: data?.message}));
                    getDetails({})
                }
            }))
        }else{
            dispatch(ActionShowAlert({message:  selected?.length === 0 ?"No Contacts Selected":selectedTags?.length === 0 ?"No Tags Selected": "Something Went Wrong", color:"red"}))
        }
    }

    const handleFilter = (filter) =>{
        setFilter(filter);
        setPage(1);
        getDetails({params: {...filter, page: 1}});
        setOpenFilter(false)
    }
    const handleSelect = (mobile) =>{
        if(selected?.includes(mobile)){
            setSelected(selected?.filter(a => a !== mobile));
        }else{
            setSelected([...selected, mobile]);
        }
        setRender(render+1);
    }
    const handleSelectAll = () =>{
        let list = contacts?.map(a => a?.mobile);
        if(contacts?.filter(a => !selected?.includes(a?.mobile))?.length === 0){
            setSelected(selected?.filter(a => !list.includes(a)));
            setRender(render+1);
        }else{
            setSelected(_.unique([...selected, ...(contacts?.map(a => a?.mobile))]));
            setRender(render+1);
        }

    }


    return <>
        <Header {...PageHeading} buttonProps={[{onClick:getDetails},{onClick: () => setIsCreate(true)}]}  />
        <PageLoading isLoading={isLoading}>

            {isCreate && <CreateContact onClose={() => setIsCreate(false)} onRefresh={getDetails} />}
            {isEdit && <CreateContact isEdit={true} mobileEdit={isEdit} onClose={() => setIsEdit(undefined)} onRefresh={getDetails} />}
            {isView && <CRMUser mobile={isView} onClose={() => setIsView(undefined)} onRefresh={getDetails} />}
            {isOpenFilter && <CRMFilter handleFilter={handleFilter} filter={filter} onClose={setOpenFilter} />}
            {isOpenSettings && <Settings onClose={setOpenSettings} />}

            <Group position={"apart"}>
                <BoldText text={`Total Results: ${((page-1) * 10) +1} - ${page*10} of ${total}`} />
                <Group>
                    <Pagination size={"xs"} page={page} onChange={setPage} total={Math.ceil(total/10)} />
                    <Button leftIcon={<IconFilter />} compact onClick={() => setOpenFilter(true)}>Open Filter</Button>
                    <Button leftIcon={<IconUsers />} compact onClick={() => setIsBulk(true)}>Bulk Operations</Button>
                    <Button leftIcon={<IconSettings />} compact onClick={() => setOpenSettings(true)}>Settings</Button>

                </Group>
            </Group>
            {isBulk && <Paper shadow={"md"} withBorder p={5} my={10}>
                <Group position={"apart"}>
                    <Group>
                        <BoldText size={"md"} text={`Selected ${selected?.length}`} />
                        <CheckBoxView
                            text={`Select All in This Page`}
                            isSelected={contacts?.filter(a => !selected?.includes(a?.mobile))?.length === 0}
                            onClick={handleSelectAll}
                        />
                    </Group>
                    <Group>
                        <Button compact sx={{backgroundColor: "#ccc"}} onClick={() => setSelected([])}>Clear Selected</Button>
                        <Button compact color={"red"} onClick={deleteSelectedContact}>Delete Selected</Button>
                        <Paper shadow={"md"} withBorder p={5}>
                            <Group>
                                <MultiSelect
                                    size={"xs"}
                                    data={tagsList}
                                    value={selectedTags}
                                    onChange={setSelectedTags}
                                    searchable
                                    clearable
                                    creatable
                                    getCreateLabel={(query) => `+ Create ${query}`}
                                    onCreate={(query) => {
                                        const item = { value: query, label: query };
                                        setTagsList((current) => [...current, item]);
                                        return item;
                                    }}
                                />
                                <Button compact onClick={BulkAssignTags}>Assign Tags</Button>
                            </Group>

                        </Paper>
                        <Button compact onClick={() => {
                            setIsBulk(false);
                            setSelected([]);
                            setSelectedTags([])
                        }}>Cancel</Button>
                    </Group>
                </Group>
            </Paper>}
            <TableView headers={["#","Mobile","Name","Email", "updated","Created","Options"]}>
                {contacts?.map((contact,index) => <tr style={{cursor: isBulk ? "Pointer": "auto"}}
                            onClick={() => isBulk && handleSelect(contact?.mobile)}
                >
                    <td>{isBulk ? <CheckBoxView isSelected={selected?.includes(contact?.mobile)} onClick={() => handleSelect(contact?.mobile)} /> : ((page - 1) * 10) + (index+1)}</td>
                    <td>{contact?.mobile}</td>
                    <td>{contact?.params?.name}</td>
                    <td>{contact?.params?.email}</td>
                    <td>{contact?.updated && moment.unix(contact?.updated).format("lll")}</td>
                    <td>{moment.unix(contact?.created).format("lll")}</td>
                    <td>
                        {!isBulk && <Group>
                            <IconView iconType={"view"} label={"View user"} onClick={() => setIsView(contact?.mobile)}/>
                            <IconView iconType={"edit"} label={"Edit user"} onClick={() => setIsEdit(contact?.mobile)}/>
                            <IconView iconType={"delete"} label={"Delete user"}
                                      onClick={() => deleteContact(contact?.mobile)}/>
                        </Group>}
                    </td>
                </tr>)}
            </TableView>
        </PageLoading>
    </>
}

export default CRMList;
