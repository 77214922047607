import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {Header, IconView, PageLoading, TableView} from "../Components/PageComponents";
import {Group, Modal, Button} from "@mantine/core";
import {FilledTextInput} from "../Sequence/CustomButton";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {ErrorText} from "../Components/TextComponents";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const PageHeading = {
    title: "Tracker",
    buttons: [
        {type: "add", title: "Create"},
        {type: "refresh", title: "Refresh"},

    ]
}

const Tracker  = () =>{
    const {sessionId,alias} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [list,setList] = useState([]);
    const [isCreate,setIsCreate] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.tracker}/list/${sessionId}`,{});
        setList(data?.list || []);
        setIsLoading(false);
    }
    useEffect(() =>{
        getDetails();
    },[])
    const handleDelete = async (id) =>{
       dispatch(ActionShowPopup({
           title: "Delete Tracker",
           content: "Are you sure want to Delete ?",
           successTitle: "Delete",
           cancelTitle: "Cancel",
           onSuccess: async () =>{
               const data = await RequestPost(`${CloudApi.tracker}/delete/${sessionId}`,{id});
               dispatch(ActionShowAlert({message: data?.message}))
               dispatch(ActionHidePopup());
               getDetails();
           }
       }))
    }

    const handlePreview = (id) =>{
        navigation(`/device/${alias}/tracker-details/${id}`)
    }


    return <>
        <Header {...PageHeading} buttonProps={[{onClick: () =>setIsCreate(true)},{onClick: getDetails}]} />
        <PageLoading isLoading={isLoading}>
            {isCreate && <CreateTracker onClose={() => setIsCreate(false)} onRefresh={getDetails} />}
            <TableView headers={["Title","Created","Options"]}>
                {list?.map(a => <tr>
                    <td width={"50%"}>{a?.title}</td>
                    <td>{moment.unix(a?.created).format("lll")}</td>
                    <td>
                        <Group>
                            <IconView iconType={"view"} onClick={() => handlePreview(a?.id)} />
                            <IconView iconType={"delete"} onClick={() => handleDelete(a?.id)} />
                        </Group>
                    </td>
                </tr>)}
            </TableView>
        </PageLoading>
    </>
}

export default Tracker;

const CreateTracker = ({onClose,onRefresh}) =>{
    const {sessionId} = useSelector(state => state.active);

    const [title,setTitle] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const [error,setError] = useState();

    const dispatch= useDispatch();

    const handleCreate = async () =>{
        if(title?.trim() !== ""){
            setIsError(false);
            setIsLoading(true);
            const data = await RequestPost(`${CloudApi.tracker}/create/${sessionId}`,{
                title
            });
            if(data?.status === 1){
                onClose();
                onRefresh();
                dispatch(ActionShowAlert({message: data?.message}))
            }else{
                setError(data?.message);
            }
            setIsLoading(false);
        }else{
            setIsError(true);
        }

    }

    return <Modal opened title={<b>Create Tracker</b>} onClose={onClose} size={500}>
        <FilledTextInput label={"Title"} value={title} onChange={setTitle} limit={15} error={isError && title?.trim() === "" ? "Title is Required": ""} />
        {error && <ErrorText text={error} />}
        <Group position={"center"} my={25}>
            <Button onClick={handleCreate} loading={isLoading} disabled={title?.trim() === ""}>Create Tracker</Button>
        </Group>
    </Modal>
}