import React, {useState} from 'react';
import {Button, Divider, Grid, Group, SegmentedControl, Select, Text} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {ActionShowAlert} from "../../../store/actions/alert";
import {CloudApi, RequestPost} from "../../../Layouts/RequestManager";
import VariablesView from "./VariablesView";
import {FilledTextInput} from "../../Sequence/CustomButton";
import {BoldText} from "../../Components/TextComponents";
import {FlowSuccessMsg} from "../../Components/PageComponents";

const payloadTypes = [
    {label: "Json", value: "json"},
    {label: "Form Type", value: "form"},
    {label: "Url Encoded", value: "url"},
];
const authTypes = [
    {label: "No Auth", value: "no"},
    {label: "Basic Auth", value: "basic"},
    {label: "Bearer Token", value: "bearer"},
];

const TriggerWebhook = ({props,value}) =>{
    const app= "triggerWebhook";
    const {sessionId,userId,sequence = []} = useSelector(state => state.active);

    const [method,setMethod] = useState(value?.method || "POST");
    const [url,setUrl] = useState(value?.url || "");
    const [payloadType,setPayloadType] = useState(value?.payloadType || "json");
    const [authType,setAuthType] = useState(value?.auth?.authType || "no");

    const [authToken,setAuthToken] = useState(value?.auth?.authToken || "");
    const [authkey,setAuthKey] = useState(value?.auth?.authkey || "");
    const [authValue,setAuthValue] = useState(value?.auth?.authValue || "");

    const [headers,setHeaders] = useState(value?.headers || []);
    const [params,setParams] = useState(value?.params || []);

    const dispatch = useDispatch();
    const [render,setRender] = useState(0);

    const [isError,setIsError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [successMsg,setSuccessMsg] = useState("");


    const handleSaveFlow = async () =>{
        if(method && url && payloadType){
            setIsError(false);
            setIsLoading(true);
            const flow = {app,sessionId,id: value?.id,method,url,payloadType, auth: {authType, authkey,authValue,authToken}, headers,params,}
            const data = await RequestPost(`${CloudApi.bsmFlows}/saveDetails/${sessionId}`,{flow,step: props?.step, id: props?.id});
            setSuccessMsg(data?.message);
            setIsLoading(false);
        }else{
            setIsError(true);
        }

    }
    const updateParams = (variables) =>{
        setParams(variables);
        setRender(render+1);
    }
    const updateHeaders = (variables) =>{
        setHeaders(variables);
        setRender(render+1);
    }
    return  <>
        <Grid.Col span={3}>
            <BoldText mt={20} text={`POST Method`} size={"md"} />
            {/*<SegmentedControl mt={20} label={"Method"} data={["GET","POST"]?.map(a => ({label: a,value: a}))} value={method} onChange={setMethod} />*/}
        </Grid.Col>
        <Grid.Col span={6}>
            <FilledTextInput label={"Endpoint Url"} value={url} onChange={setUrl} />
        </Grid.Col>
        <Grid.Col span={6}>
            <Select label={"Payload Type"} data={payloadTypes} value={payloadType} onChange={setPayloadType} />
        </Grid.Col>
        <Grid.Col span={6}>
            <Select label={"Authentication"} data={authTypes} value={authType} onChange={setAuthType} />
        </Grid.Col>
        {authType === "basic" && <>
             <Grid.Col span={3}>
                    <FilledTextInput label={"Key"}   value={authkey} onChange={setAuthKey} />
             </Grid.Col>
             <Grid.Col span={3}>
                    <FilledTextInput label={"Value"}  value={authValue} onChange={setAuthValue} />
             </Grid.Col>
        </>}
        {authType === "bearer" && <Grid.Col span={6}>
            <FilledTextInput label={"Token"} value={authToken} onChange={setAuthToken} />
        </Grid.Col>}


        <VariablesView label={"Headers"} variables={headers} props={props} updateVariables={updateHeaders} addVariable={true} />
        <Grid.Col span={12}><Divider /></Grid.Col>
        <VariablesView label={"Parameters"} variables={params} props={props} updateVariables={updateParams} addVariable={true} />

        <Grid.Col span={3}>
            <Group position={'apart'} mt={25}>
                <Button onClick={handleSaveFlow} variant={'gradient'} loading={isLoading}>Save</Button>
                {successMsg && <FlowSuccessMsg message={successMsg} onClose={setSuccessMsg} />}
                {isError && <Text color={"red"} weight={"bold"}>Please Fill All Fields</Text>}
            </Group>
        </Grid.Col>
    </>
}

export default TriggerWebhook;
