import React, {Component, useEffect, useState} from "react";
import {Drawer, Group, Text, TextInput, Button, Grid, Paper, Stack} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {IconCircle, IconCircleCheck} from "@tabler/icons";
import queryString from "query-string";
import {PageLoading} from "../Components/PageComponents";
import {CloudApi, RequestPost, serverUrls} from "../../Layouts/RequestManager";
import {socket} from "../Alerts/Socket";
import {SetupCred} from "../../Constants/Setup";

const IntegrationDrawer = ({app,onClose,connId,updateConnection}) =>{
    const {sessionId,userId} = useSelector(state => state.active);
    const [accounts,setAccounts] = useState([]);
    const [selected,setSelected] = useState(connId || "");
    const [isLoading,setIsLoading] = useState("");

    useEffect(() =>{
        getDetails();
    },[])

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.bsmConnections}/list/${sessionId}`, {userId,app});
        setAccounts(data?.accounts);
        const sel = data?.accounts?.length === 0 ? "new" :  selected !== 'new' ? selected : data?.accounts?.[data?.accounts?.length -1]?._id;
        setSelected(sel);
        setIsLoading(false);

    }
    const handleSave = async () =>{
       updateConnection(selected);
    }

    return <Drawer opened={true} title={<Text weight={"bold"} variant={"gradient"} >Integration</Text>} position={'right'}  padding="sm" size="xl"
                lockScroll={false} size={"50%"} onClose={onClose}>
            <PageLoading isLoading={isLoading}>
                <Text weight={"bold"}>Choose Connection</Text>
                {accounts?.map(a =>{
                    return <Paper shadow={"md"} my={1} p={2} withBorder={true} onClick={() => setSelected(a?._id)}>
                        <Group sx={{gap: 3}}>
                            {selected === a?._id ? <IconCircleCheck />: <IconCircle />}
                            <Stack sx={{gap: 1}} mx={5}>
                                <Text weight={"bold"} variant={"gradient"}>{a?.info?.title}</Text>
                                <Text color={"dimmed"}>{a?.info?.email || a?.info?.name}</Text>
                            </Stack>
                        </Group>
                    </Paper>
                })}
                <Paper shadow={"md"} withBorder={true} onClick={() => setSelected("new")}>
                    <Group>
                        {selected === "new" ? <IconCircleCheck />: <IconCircle />}
                        <Text>Add New Connection</Text>
                    </Group>
                </Paper>
                {selected === 'new' && <>
                    {app === 'ck' && <>
                        <Text weight={"bold"} my={10}>Add new Convertkit Account - {accounts?.length}</Text>
                        <ConvertKit accounts={accounts} onRefresh={getDetails} />
                    </>}
                    {app === 'instamojo' && <>
                        <Text weight={"bold"} my={10}>Add new Intamojo Account - {accounts?.length}</Text>
                        <Instamojo accounts={accounts} onRefresh={getDetails}  />
                    </>}
                    {app === 'fbLeads' && <>
                        <Text weight={"bold"} my={10}>Add new Facebook Account - {accounts?.length}</Text>
                        <FbLeads accounts={accounts} onRefresh={getDetails} />
                    </>}
                    {app === 'razorpay' && <>
                        <Text weight={"bold"} my={10}>Add new RazorPay Account - {accounts?.length}</Text>
                        <RazorPay accounts={accounts} onRefresh={getDetails} />
                    </>}
                </>}
                {selected && selected !== 'new' && <Group my={20} position={'apart'}>
                    <Button variant={'gradient'} sx={{width: "45%"}} onClick={handleSave}>Save</Button>
                    <Button variant={'outline'} sx={{width: "45%"}} onClick={onClose}>Cancel</Button>
                </Group>}
            </PageLoading>
        </Drawer>
}

export default IntegrationDrawer;



const ConvertKit = ({accounts,onRefresh}) =>{

    const [title,setTitle] = useState(`Convertkit#${accounts.length + 1}`);
    const [apiKey,setApiKey] = useState("");
    const [apiSecret,setApiSecret] = useState("");
    const [error,setError] = useState(false);
    const [errorText,setErrorText] = useState("");
    const [loading,setLoading] = useState(false);
    const {sessionId,userId} = useSelector(state => state.active);

    const AddConvertKitAccount = async () =>{
        if(apiKey?.trim() !== "" && apiSecret?.trim() !== ""){
            setLoading(true);
            const details = await RequestPost(`${CloudApi.convertkit}/create/${sessionId}`,{apiKey, apiSecret,title,userId})
            if(details?.status === 1){
                onRefresh();
            }else{
                setLoading(false);
                setErrorText("Invalid Api Key or Api Secret");
            }
        }else{
            setError(true);
        }
    }

    return  <Grid>
        <Grid.Col span={12}>
            <TextInput
                label={"Account Name"}
                required
                error={error && apiKey?.trim() === "" ?"Api Key is Required": ""}
                value={title}
                onChange={(e) => setTitle(e.target.value)} />
        </Grid.Col>
        <Grid.Col span={6}>
            <TextInput
                label={"Api Key"}
                required
                error={error && apiKey?.trim() === "" ?"Api Key is Required": ""}
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)} />
        </Grid.Col>
        <Grid.Col span={6}>
            <TextInput
                label={"Api Secret"}
                required
                error={error && apiSecret?.trim() === "" ?"Api Secret is Required": ""}
                value={apiSecret}
                onChange={(e) => setApiSecret(e.target.value)}/>
        </Grid.Col>
        {errorText && <Grid.Col span={12}>
            <Text color={"red"} my={10} align={"center"}>{errorText}</Text>
        </Grid.Col>}
        <Grid.Col span={12}>
            <Group position={"center"}>
                <Button loading={loading} variant={'gradient'} onClick={AddConvertKitAccount}>Connect</Button>
            </Group>
        </Grid.Col>
    </Grid>
}
const Calendly = ({state}) =>{
    const title = `Calendly#${state.accounts.length + 1}`;
    const userId = useSelector(state => state.user.id);

    const openAuth = () =>{
        let url = `https://auth.calendly.com/oauth/authorize?client_id=${SetupCred?.calendly?.clientId}&response_type=code&redirect_uri=\thttps://coach.businesssalesmachine.com/auth/calendly?userId=${userId}&title=${title}`;
        let windowName = 'Calendly Integration';
        let windowFeatures = 'width=1000,height=600';
        let popup = window.open(url, windowName, windowFeatures);
    }
    return  <Grid>
                <Grid.Col span={12}>
                    <Button onClick={openAuth}>Connect Calendly Account</Button>
                </Grid.Col>
        </Grid>
}

const FbLeads = ({accounts,onRefresh}) =>{
    const {userId} = useSelector(state => state.active);
    const openAuth = () =>{
        const oauth2Endpoint = 'https://www.facebook.com/v18.0/dialog/oauth'
        const params = {
            client_id: '3455943534717479',
            redirect_uri: `https://wba1.businesssalesmachine.com/facebook/leads`,
            response_type: 'code',
            config_id: '867902765530952',
            state: "alias"
        }

        const oauthURL = `${oauth2Endpoint}?${new URLSearchParams(params).toString()}`
        let windowName = 'BSM + WHATSAPP';
        let windowFeatures = 'width=1000,height=600';
        window.open(oauthURL,windowName, windowFeatures);
    }
    return  <Grid>
                <Grid.Col span={12}>
                    <Button onClick={openAuth}>Connect Facebook Account</Button>
                </Grid.Col>
        </Grid>
}

const Instamojo = ({accounts,onRefresh}) =>{
    const [title,setTitle] = useState(`Instamojo#${accounts.length + 1}`);
    const [apiKey,setApiKey] = useState("");
    const [authToken,setAuthToken] = useState("");
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(false);
    const {userId,sessionId} = useSelector(state => state.active);

    const AddInstamojoAccount = async () =>{
        if(apiKey?.trim() !== "" && authToken?.trim() !== ""){
            setLoading(true);
            const data = await RequestPost(`${CloudApi.bsmConnections}/create/${sessionId}`, {userId,app:"instamojo", info: {title}, details: {apiKey,authToken},});
            onRefresh();
            setLoading(false);
        }else{
            setError(true);
        }
    }

    return  <Grid>
        <Grid.Col span={12}>
            <TextInput
                label={"Account Name"}
                required
                error={error && apiKey?.trim() === "" ?"Account Name is Required": ""}
                value={title}
                onChange={(e) => setTitle(e.target.value)} />
        </Grid.Col>
        <Grid.Col span={6}>
            <TextInput id="outlined-basic"
                       label={"Private Api Key"} name={'key'}
                       fullWidth
                       value={apiKey}
                       onChange={(e) => setApiKey(e.target.value)}
                       error={error && apiKey.trim() === '' ? 'Key is Required' : ""}
            />
        </Grid.Col>
        <Grid.Col span={6}>
            <TextInput size={'xs'} id="outlined-basic" label={"Private Auth Token"} name={'secret'}
                       fullWidth value={authToken}
                       onChange={(e) => setAuthToken(e.target.value)}
                       error={error && authToken.trim() === '' ?'Auth Token is Required':""} />
        </Grid.Col>
        <Grid.Col span={12}>
            <Group position={"center"}>
                <Button loading={loading} variant={'gradient'} onClick={AddInstamojoAccount}>Connect</Button>
            </Group>
        </Grid.Col>
    </Grid>
}
const RazorPay = ({accounts,onRefresh}) =>{
    const {userId,sessionId} = useSelector(state => state.active);

    const [title,setTitle] = useState(`RazorPay#${accounts.length + 1}`);
    const [apiKey,setApiKey] = useState("");
    const [apiSecret,setApiSecret] = useState("");
    const [error,setError] = useState(false);
    const [loading,setLoading] = useState(false);

    const AddRazorPayAccount = async () =>{
        if(apiKey?.trim() !== "" && apiSecret?.trim() !== ""){
            setLoading(true);
            const data = await RequestPost(`${CloudApi.bsmConnections}/create/${sessionId}`, {userId,app:"razorpay",   info: {title}, details: {key_id: apiKey,key_secret: apiSecret}});
            onRefresh();
        }else{
            setError(true);
        }
    }

    return  <Grid>
        <Grid.Col span={12}>
            <TextInput
                label={"Account Name"}
                required
                error={error && apiKey?.trim() === "" ?"Account Name is Required": ""}
                value={title}
                onChange={(e) => setTitle(e.target.value)} />
        </Grid.Col>
        <Grid.Col span={6}>
            <TextInput id="outlined-basic"
                       label={"Live Key Id"}
                       fullWidth
                       value={apiKey}
                       onChange={(e) => setApiKey(e.target.value)}
                       error={error && apiKey.trim() === '' ? 'Key is Required' : ""}
            />
        </Grid.Col>
        <Grid.Col span={6}>
            <TextInput size={'xs'} id="outlined-basic"
                       label={"Live Key Secret"}
                       fullWidth value={apiSecret}
                       onChange={(e) => setApiSecret(e.target.value)}
                       error={error && apiSecret.trim() === '' ?'Api Secret is Required':""} />
        </Grid.Col>
        <Grid.Col span={12}>
            <Group position={"center"}>
                <Button loading={loading} variant={'gradient'} onClick={AddRazorPayAccount}>Connect</Button>
            </Group>
        </Grid.Col>
    </Grid>
}
